<template>
  <ion-row>
    <ion-col class="ion-no-padding">
      <ion-card>
        <ion-item lines="none" class="ion-no-padding">
          <ion-card-header>
            <ion-card-title> {{ resource.appeal_type.name }} </ion-card-title>
            <ion-card-subtitle
              >{{ resource.user.user_detail.name }}
              {{ user_details(resource) }}</ion-card-subtitle
            >
          </ion-card-header>
        </ion-item>
        <ion-card-header style="padding-top: 0">
          <ion-card-subtitle>
            <ion-text>
              {{ resource.name }}
              <br />
              {{ new Date(resource.appeal_from_date).toDateString() }} to
              {{ new Date(resource.appeal_to_date).toDateString() }}
            </ion-text></ion-card-subtitle
          >
        </ion-card-header>
        <ion-card-content>
          {{ resource.description }}
          <br />
          <br />
          Created: {{ new Date(resource.created_at).toDateString() }}
          <br />
        </ion-card-content>
        <ion-item>
          <ion-label slot="start"
            >Status: {{ resource.appeal_state.name }}</ion-label
          >
        </ion-item>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6">
      <ion-button
        type="button"
        expand="full"
        color="warning"
        class="ion-margin-vertical"
        @click="updateAppealState('Rejected')"
      >
        Reject
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <ion-button
        type="button"
        expand="full"
        class="ion-margin-vertical"
        @click="updateAppealState('Approved')"
      >
        Approve
      </ion-button>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonButton,
  IonText,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonCardContent,
  loadingController,
  alertController,
} from "@ionic/vue";

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonLabel,
    IonItem,
    IonButton,
    IonText,
    IonCard,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
  },
  data() {
    return {
      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });
      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async updateAppealState(state) {
      let data = {
        id: this.resource.id,
        appeal_state: state,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("appeal/updateResource", data);
        spinner.dismiss();
        this.name = null;
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();
      }
    },

    user_details(data) {
      let item = data;
      const is_student = item.user.student ? true : false;
      const is_teacher = item.user.teacher ? true : false;
      let response = "";

      if (is_teacher) {
        response = `(Teacher)`;
      } else if (is_student) {
        response = `(Student: ${item.user.student.section_standard.standard.name} - ${item.user.student.section_standard.section.name}, Roll: ${item.user.student.roll_no})`;
      } else {
        response = `User`;
      }
      return response;
    },
  },
};
</script>